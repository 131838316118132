import type { AxiosError, AxiosResponse } from "axios"
import httpClient from "./httpClient"
import type { GetVendorEmailApiResponse, PostVendorSignUpApiBody, PostVendorSignUpApiResponse } from "./interfaces/Vendor.api.types"
import { handleApiError } from "@/utils/handleApiError"

const baseUrl = import.meta.env.VITE_ROOT_URL ?? `https://${window.location.hostname}`

const getVendorEmail = async (caseId: string, emailId: string): Promise<GetVendorEmailApiResponse> => {
    try {
        const response: AxiosResponse<GetVendorEmailApiResponse> = await httpClient.get<GetVendorEmailApiResponse>(
            `${baseUrl}/vendor/api/signup/${caseId}/${emailId}`)
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

const signUpVendor = async (caseId: string, emailId: string, body: PostVendorSignUpApiBody): Promise<PostVendorSignUpApiResponse> => {
    try {
        const response: AxiosResponse<PostVendorSignUpApiResponse> = await httpClient.post<PostVendorSignUpApiResponse>(
            `${baseUrl}/vendor/api/signup/${caseId}/${emailId}`, {
            ...body
        })
        return response.data
    } catch (error) {
        throw handleApiError(error as AxiosError)
    }
}

export default { getVendorEmail, signUpVendor }