import type { GetVendorEmailApiResponse, PostVendorSignUpApiResponse } from "@/api/interfaces/Vendor.api.types";
import vendorApi from "@/api/vendor.api";
import type { VendorSignUpForm } from "@/interfaces/Vendor.types";
import { defineStore } from "pinia"


export const useVendorStore = defineStore('vendor', () => {

    const getVendorEmail = async (caseId: string, emailId: string): Promise<GetVendorEmailApiResponse> => {
        try {
            const response = await vendorApi.getVendorEmail(caseId, emailId)
            return response
        } catch (error) {
            console.error(error)
            throw new Error(error as string)
        }
    }

    const signUpVendor = async (caseId: string, emailId: string, formData: VendorSignUpForm): Promise<PostVendorSignUpApiResponse> => {
        try {
            const response = await vendorApi.signUpVendor(caseId, emailId, {
                email_address: formData.username,
                first_name: formData.firstName,
                last_name: formData.lastName,
                password: formData.password,
                company_name: formData.companyName
            })
            return response
        } catch (error) {
            console.error(error)
            throw new Error(error as string)
        }
    }

    return {
        getVendorEmail,
        signUpVendor
    }

});
